import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Tick Who" keywords={[`doctor who`, `tick sheet`, `blu-ray`]} />
    <h1>Tick Who</h1>
    <p>We're building up tick lists for Doctor Who episodes, which will launch fully here sometime. In the meantime, some choice selections:</p>
    <Link to="/blu-ray/">Doctor Who on Blu-Ray</Link>
  </Layout>
)

export default IndexPage
